import {
  extendTheme,
  withDefaultVariant,
  type ThemeConfig,
} from '@chakra-ui/react';
import { colors } from './colors';
import { Input } from '@/theme/components/Input';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

export const blueGradient =
  'linear-gradient(90deg, rgba(96,33,221,1) 0%, rgba(22,137,220,1) 100%)';

export const breakpoints = {
  base: '0em', // 0px
  sm: '30em', // ~480px. em is a relative unit and is dependant on the font size.
  md: '48em', // ~768px
  lg: '62em', // ~992px
  xl: '80em', // ~1280px
  '2xl': '96em', // ~1536px
};

const space = {
  px: '1px',
  0.5: '0.125rem', // 2px
  1: '0.25rem', // 4px
  1.5: '0.375rem', // 6px
  2: '0.5rem', // 8px
  2.5: '0.625rem', // 10px
  3: '0.75rem', // 12px
  3.5: '0.875rem', // 14px
  4: '1rem', // 16px
  5: '1.25rem', // 20px
  6: '1.5rem', // 24px
  7: '1.75rem', // 28px
  8: '2rem', // 32px
  9: '2.25rem', // 36px
  10: '2.5rem', // 40px
  12: '3rem', // 48px
  14: '3.5rem', // 56px
  16: '4rem', // 64px
  20: '5rem', // 80px
  24: '6rem', // 96px
  28: '7rem', // 112px
  32: '8rem', // 128px
  36: '9rem', // 144px
  40: '10rem', // 160px
  44: '11rem', // 176px
  48: '12rem', // 192px
  52: '13rem', // 208px
  56: '14rem', // 224px
  60: '15rem', // 240px
  64: '16rem', // 256px
  72: '18rem', // 288px
  80: '20rem', // 320px
  96: '24rem', // 384px
};

const sizes = {
  ...space,
  max: 'max-content',
  min: 'min-content',
  full: '100%',
  '3xs': '14rem',
  '2xs': '16rem',
  xs: '20rem',
  sm: '24rem',
  md: '28rem',
  lg: '32rem',
  xl: '36rem',
  '2xl': '42rem',
  '3xl': '48rem',
  '4xl': '56rem',
  '5xl': '64rem',
  '6xl': '72rem',
  '7xl': '80rem',
  '8xl': '90rem',
  container: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  },
};

const fontSizes = {
  // px sizes are based on 16px base font size
  xs: '0.75em', // 12px
  sm: '0.875em', // 14px
  md: '1em', // 16px
  lg: '1.125em', // 18px
  xl: '1.25em', // 20px
  xlPlus: '1.375em', // 22px
  '2xl': '1.5em', // 24px
  '3xl': '1.875em', // 30px
  '4xl': '2.25em', // 36px
  '5xl': '3em', // 48px
  '6xl': '3.75em', // 60px
  '7xl': '4.5em', // 72px
  '8xl': '6em', // 96px
  '9xl': '8em', // 128px
};

const components = {
  Button: {
    fontWeight: '700',
    variants: {
      base: {
        color: 'black.10',
        bg: 'gray.500',
        fontSize: 'md',
      },
      solid: {
        borderRadius: '50px',
        color: 'gray.610',
        bg: 'linear-gradient(to left, #294077 0%, #252a50 100%)',
        borderColor: 'linear-gradient(to left, #294077 0%, #252a50 100%)',
        boxShadow: '0px 2px 0px 0 rgba(0,0,0,0.04)',
        px: '10px',
        _hover: {
          bg: 'linear-gradient(-8.77deg, #6021dd -137.89%, #1689dc 355.58%)',
        },
        _focusVisible: { bg: 'black.10' },
        _active: { bg: 'black.10' },
        _disabled: {
          bg: 'purple.10',
          color: 'purple.20',
          cursor: 'not-allowed',
          _hover: { bg: 'purple.10' },
        },
      },
    },
  },
  Switch: {
    baseStyle: {
      container: {
        width: '42px',
        height: '22px',
      },
      thumb: {
        bg: 'rgba(255, 255, 255, 0.9)',
        width: '18px',
        height: '18px',
      },
      track: {
        width: '38px',
        height: '18px',
        bg: 'purple.10',
        _checked: {
          bg: 'blue.10',
        },
      },
    },
  },
  Input,
};

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'gray.610',
        color: 'purple.10',
      },
    },
  },
  breakpoints,
  colors,
  fontSizes,
  sizes,
  space,
  components,
  ...config,
  ...withDefaultVariant({
    variant: 'outline',
    components: ['Button'],
  }),
});

export default theme;
