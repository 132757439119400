export const colors = {
  gray: {
    10: '#F5F5F5',
    20: '#BCBCBC',
    30: '#B6B6B6',
    40: '#D0D0D0',
    50: '#F0EFEF',
    60: '#E2E2E2',
    70: '#F2F2F2',
    80: '#FAFAFA',
    90: '#808080',
    100: '#DCDCDC',
    110: '#B2B2B2',
    120: '#E6E6E6',
    130: '#ACACAC',
    140: '#999',
    150: '#EDEDED',
    160: '#A8A8A8',
    170: '#EBEBEB',
    180: '#F7F7F7',
    200: '#ECECEC',
    210: '#F8F8F8',
    220: '#EBEBEB', // TODO: duplicate
    260: '#E7E7E7',
    270: '#828282',
    280: '#E0E0E0',
    290: '#4D4034',
    300: '#EFEFEF',
    500: '#7A7A7A',
    610: '#FAFBFD',
    620: '#ECEDFA',
    900: '#292D32',
    910: '#292D32', // TODO: repeat
  },

  orange: {
    10: '#E9530D',
    20: '#F76F46',
    30: '#FBCEB1',
    40: '#F64E00',
    50: '#FFD4AD',
  },

  green: {
    10: '#00B75A',
    20: '#008B44',
    30: '#E0FFEF',
    40: '#0FA244',
  },

  red: {
    10: '#B72100',
    20: '#FFE6E0',
  },

  yellow: {
    10: '#E7A600',
    20: '#F76F46',
  },

  purple: {
    10: '#7A7B9E',
    20: '#9C9DC1',
    30: '#D7D8FE',
  },

  blue: {
    10: '#2C6BDD',
  },

  black: {
    10: '#000022',
  },
};

export default colors;
