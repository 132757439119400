import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
);

export const Input = defineMultiStyleConfig({
  variants: {
    outline: {
      field: {
        borderColor: 'purple.30',
        bg: 'gray.610',
        borderRadius: '8px',
        _hover: {
          borderColor: 'purple.30',
        },
        _placeholder: {
          color: 'purple.20',
        },
      },
    },
  },
  defaultProps: {
    variant: 'outline',
  },
});
